<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Exit Interview Applications</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex xs12 align-self-center fill-height pa-4>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in applications"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.create_date"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.create_date.slice(0, 10) }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.employeeId"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.employeeId.name }}</span
                      >
                    </td>

                    <td>
                      <v-layout wrap justify-start>
                        <!-- <v-flex xs6 sm4 align-self-center pa-2>
                          <v-dialog
                            persistent
                            v-model="item.delete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                depressed
                                dark
                                title="Delete"
                                v-on="on"
                                v-bind="attrs"
                                color="#005f32"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                  text-left
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Delete Confirmation</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-card-title
                                >Are you sure you want to delete this
                                application?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="error"
                                  tile
                                  dark
                                  @click="item.delete = false"
                                  >Cancel</v-btn
                                >
                                &nbsp;
                                <v-btn
                                  color="#005f32"
                                  outlined
                                  tile
                                  @click="deleteItem(item)"
                                  >Delete</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex> -->
                        <v-flex sm4 align-self-center pa-2>
                          <v-dialog v-model="item.view" max-width="70%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                title="View"
                                color="#005f32"
                                v-on="on"
                                v-bind="attrs"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Application Details</span
                                  >
                                </v-flex>
                                <v-flex
                                  xs12
                                  align-self-center
                                  class="kumbhRegular"
                                >
                                  <v-layout wrap pa-4>
                                    <v-flex xs12 align-self-center text-right>
                                      <span
                                        v-if="item.create_date"
                                        class=""
                                        style="font-family: kumbhMedium"
                                        >{{
                                          item.create_date.slice(0, 10)
                                        }}</span
                                      >
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-2
                                      v-if="item.employeeId"
                                    >
                                      <span class="kumbhRegular"
                                        >Employee Details :
                                      </span>
                                      <span
                                        style="font-size: 18px; color: #005f32"
                                        class="vhover kumbhSemibold"
                                      >
                                        <a
                                          :href="
                                            'viewEmployee?id=' +
                                            item.employeeId._id
                                          "
                                        >
                                          {{ item.employeeId.name }}
                                        </a>
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      align-self-center
                                      v-if="item.questionAnswers"
                                    >
                                      <v-layout
                                        wrap
                                        v-if="item.questionAnswers.length > 0"
                                      >
                                        <v-flex
                                          xs12
                                          pa-1
                                          v-for="(
                                            split, s
                                          ) in item.questionAnswers"
                                          :key="s"
                                        >
                                          <v-card
                                            flat
                                            class="rounded-0 pa-3 kumbhMedium"
                                          >
                                            <v-layout wrap>
                                              <v-flex xs1 align-self-center>
                                                <span class="kumbhSemibold">
                                                  {{ s + 1 }}.
                                                </span>
                                              </v-flex>
                                              <v-flex xs11 align-self-center>
                                                <v-layout
                                                  wrap
                                                  pa-2
                                                  text-left
                                                  v-if="split.question"
                                                >
                                                  <v-flex
                                                    xs12
                                                    align-self-center
                                                    text-left
                                                    pa-1
                                                  >
                                                    <span
                                                      class="kumbhSemibold"
                                                      style="color: #005f32"
                                                    >
                                                      Qn.{{ split.question }}
                                                    </span>
                                                  </v-flex>
                                                  <!-- <v-flex xs12 >
                                                <v-divider></v-divider>
                                              </v-flex> -->

                                                  <v-flex
                                                    xs12
                                                    align-self-center
                                                    text-left
                                                    pa-1
                                                    v-if="
                                                      split.answerType == 'text'
                                                    "
                                                  >
                                                    <v-card outlined tile>
                                                      <v-layout wrap>
                                                        <v-flex xs12 align-self-center pa-1>
                                                           <span
                                                            class="kumbhMedium"
                                                            style="
                                                              color: #005f32;
                                                              font-size: 12px;
                                                            "
                                                            >Answer</span
                                                          >
                                                        </v-flex>
                                                        <v-flex xs12 align-self-center pa-1>
                                                           <span class="kumbhMedium" >
                                                      {{ split.answer }}
                                                    </span>
                                                        </v-flex>
                                                      </v-layout>
                                                     
                                                  
                                                     
                                                    </v-card>
                                                  </v-flex>

                                                  <v-flex
                                                    xs12
                                                    align-self-center
                                                    text-left
                                                    pa-1
                                                    v-if="
                                                      split.answerType !=
                                                        'text' &&
                                                      split.optionsAnswer
                                                        .length > 0
                                                    "
                                                  >
                                                    <v-card outlined tile>
                                                      <v-layout wrap>
                                                        <v-flex xs12 pa-1>
                                                          <span
                                                            class="kumbhMedium"
                                                            style="
                                                              color: #005f32;
                                                              font-size: 12px;
                                                            "
                                                            >Answer</span
                                                          >
                                                        </v-flex>
                                                        <v-flex
                                                          xs12
                                                          pa-1
                                                          align-self-center
                                                          text-left
                                                          v-for="(
                                                            j, ind
                                                          ) in split.optionsAnswer"
                                                          :key="ind"
                                                        >
                                                          <span
                                                            class="kumbhMedium"
                                                          >
                                                            {{ j.optionvalue }}
                                                          </span>
                                                        </v-flex>
                                                      </v-layout>
                                                    </v-card>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-card>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      headers: [
        { text: "Category Name", value: "name" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      applications: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      answerType: null,
      answerTypes: [
        {
          name: "Text",
          val: "text",
        },
        {
          name: "Dropdown",
          val: "dropdown",
        },
        // {
        //   name: "Checkbox",
        //   val: "checkbox",
        // },
      ],
      question: null,
      options: [],
      isRequired: false,
      order: null,
      isMultiSelect: false,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },

  methods: {
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/exitInterviewapplications/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/exitInterviews/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.applications = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },
  },
};
</script>
